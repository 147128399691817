body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.my-deals-layout {
  display: flex;
  flex-direction: column;
}

.my-deals-layout .filters-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.my-deals-layout .filters-list span div.ant-select {
  width: 200px;
}

.my-deals-layout .filters-list span div.ant-select {
  margin-right: 10px
}

.ant-collapse-content-box .ant-row.ant-form-item {
  margin-bottom: 0
}

.ant-row.interest-selection-row {
  display: flex;
  align-items: center;
}

.ant-btn.ml-15 {
  margin-left: 15px;
}

.ant-btn.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-100 {
  margin-top: 100px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

hr {
  opacity: 0.2
}

.ant-btn.header-plus-dropdown {
  color: gray;
  padding: 0 7px 0 13px;
}

.ant-btn.header-user-dropdown {
  color: gray;
  padding: 0 7px;
}

li.ant-menu-item.notification {
  height: 114px;
}

