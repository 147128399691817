.container {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    background: #F9F9F9
}

.form_style {
    background: #FFFFFF; 
    padding: 20px 25px;
    min-height: 150px; 
    display: flex; 
    flex-direction: column; 
    justify-content: space-between;
    border: 1px solid lightgrey; 
    border-radius: 3px;
}

.spin {
    margin-top: 15px;
}