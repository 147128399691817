.container {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #F9F9F9
}

.form_style {
    background: #FFFFFF; 
    padding: 20px 25px;
    min-height: 170px; 
    display: flex; 
    flex-direction: column; 
    justify-content: space-between;
    border: 1px solid lightgrey; 
    border-radius: 3px;
}

.tl_left {
    margin: 10px 0;
    text-align: left;
    -webkit-text-align: left;
    -moz-text-align: left;
}

.tl_right {
    margin: 10px 0 0 0;
    text-align: right;
    -webkit-text-align: right;
    -moz-text-align: right;
}

.linkedin_btn {
    padding: 0 62px; 
    margin-top: 10px; 
    height: 45px
}

.linkedin_btn_text {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    font-size: 12px;
}

.signup_link {
    background: #FFFFFF;
    margin-top: 5px;
    padding: 10px 47px;
    border: 1px solid lightgrey; 
    border-radius: 3px;
}

.spin {
    margin-top: 15px;
}