.container {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #F9F9F9
}

.form_style {
    background: #FFFFFF; 
    padding: 20px 25px;
    min-height: 265px; 
    display: flex; 
    flex-direction: column; 
    justify-content: space-between;
    border: 1px solid lightgrey; 
    border-radius: 3px;
}

.linkedin_btn {
    padding: 0 60px; 
    margin-top: 10px; 
    height: 45px
}

.linkedin_btn_text {
    display: flex;
    justify-content: center;
    margin-top: 5px;
    font-size: 12px;
}

.login_link {
    background: #FFFFFF;
    margin-top: 5px;
    padding: 10px 30px;
    border: 1px solid lightgrey; 
    border-radius: 3px;
}

.spin {
    margin-top: 15px;
}

.agreement_section {
    margin-top: 5px;
    display: flex;
    justify-content: space-around;  
    font-size: 10px;
    color: gray;
}